import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import icon_arrowdown from '../../../../assets/icons/icon_arrowdown.svg';

import * as S from './style';
import { useItemIsOpen } from '../../context/ItemThatOpen';

const handleSubMenuClick = (option, onIsOpenMenu) => {
  option.action(option);
  onIsOpenMenu(null);
};

export const MenuDropdown = ({ options, isOpenSidebar, onIsOpenMenu }) => (
  <>
    <S.MenuBg
      tabIndex="-1"
      className="menu-bg"
      onClick={() => onIsOpenMenu(null)}
    />
    <S.MenuDrop isOpenSidebar={isOpenSidebar}>
      {options?.map((option, index) => (
        <li key={String(index)}>
          <button
            type="button"
            onClick={() => handleSubMenuClick(option, onIsOpenMenu)}
          >
            {option.name}
          </button>
        </li>
      ))}
    </S.MenuDrop>
  </>
);

MenuDropdown.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  onIsOpenMenu: PropTypes.func.isRequired,
  isOpenSidebar: PropTypes.bool.isRequired,
};

export const SubMenu = ({ options, isOpenSidebar, onIsOpenMenu }) => (
  <S.SubMenuProfile OpenSidebar={isOpenSidebar}>
    {options.map((option, index) => (
      <li key={String(index)}>
        <button
          type="button"
          title={option.name}
          onClick={() => handleSubMenuClick(option, onIsOpenMenu)}
        >
          {option.name}
        </button>
      </li>
    ))}
  </S.SubMenuProfile>
);

SubMenu.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  onIsOpenMenu: PropTypes.func.isRequired,
  isOpenSidebar: PropTypes.bool.isRequired,
};

const Profile = ({ profile, isOpenSidebar, index }) => {
  const location = useLocation();

  const { itemIsOpen, setItemIsOpen } = useItemIsOpen();

  return (
    <>
      <S.ContainerProfile
        isOpenSidebar={isOpenSidebar}
        isOpenMenu={itemIsOpen === index}
        className={
          profile.route === `/${location.pathname.split('/')[1]}` && 'emphasis'
        }
        cursor={profile.menu?.length === 0 ? 'default' : 'pointer'}
      >
        <>
          <div
            className="profile-details"
            onClick={() => {
              setItemIsOpen(index);
            }}
          >
            {profile?.image_url ? profile?.image_url : <i>{profile.icon}</i>}
            <div className="name_job">
              <div className="name">{profile?.setor}</div>
              <div className="job">{profile?.name}</div>
            </div>
            <span className="tooltip">
              {profile?.setor} <br /> <strong>{profile?.name}</strong>
            </span>
          </div>

          {profile.menu.length > 0 && (
            <button
              onClick={() => {
                setItemIsOpen(index);
              }}
              type="button"
              className="btn-down"
            >
              <img src={icon_arrowdown} alt="icon arrow down" />
            </button>
          )}
        </>

        {itemIsOpen === index && !isOpenSidebar && profile.menu.length > 0 && (
          <MenuDropdown
            options={profile?.menu}
            isOpenSidebar={isOpenSidebar}
            onIsOpenMenu={setItemIsOpen}
          />
        )}
      </S.ContainerProfile>
      {itemIsOpen === index && isOpenSidebar && profile.menu.length > 0 && (
        <SubMenu
          options={profile?.menu}
          isOpenSidebar={isOpenSidebar}
          onIsOpenMenu={setItemIsOpen}
        />
      )}
    </>
  );
};

Profile.propTypes = {
  profile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  isOpenSidebar: PropTypes.bool,
  index: PropTypes.number,
};

Profile.defaultProps = {
  profile: {},
  isOpenSidebar: false,
  index: null,
};

export default Profile;
