import produce from 'immer';

import types from './types';

export const INITIAL_STATE = {
  reportTemplates: { data: null, error: false, loading: false },
  foundReportTemplates: { data: null, error: false, loading: false },
  foundReportCalls: { data: null, error: false, loading: false },
  foundReportDashboard: { data: null, error: false, loading: false },
  foundReports: { data: null, error: false, loading: false },
  foundReportErrors: { data: null, error: false, loading: false },
  isLoadingReportTemplate: false,
  reportDashboardTemplates: { data: null, error: false, loading: false },
  reportDashboardFromTemplate: { data: null, error: false, loading: false },
};

export default function ivrReports(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.SEARCH_IVR_REPORT_TEMPLATES: {
        draft.foundReportTemplates.error = false;
        draft.foundReportTemplates.loading = true;
        break;
      }
      case types.SEARCH_IVR_REPORT_TEMPLATES_SUCCESS: {
        draft.foundReportTemplates.error = false;
        draft.foundReportTemplates.loading = false;
        draft.foundReportTemplates.data = action.payload;
        break;
      }
      case types.SEARCH_IVR_REPORT_TEMPLATES_FAILURE: {
        draft.foundReportTemplates.data = null;
        draft.foundReportTemplates.error = true;
        draft.foundReportTemplates.loading = false;
        break;
      }

      case types.FETCH_IVR_REPORT_TEMPLATES: {
        draft.reportTemplates.error = false;
        draft.reportTemplates.loading = true;
        break;
      }
      case types.FETCH_IVR_REPORT_TEMPLATES_SUCCESS: {
        draft.reportTemplates.error = false;
        draft.reportTemplates.loading = false;
        draft.reportTemplates.data = action.payload;
        break;
      }
      case types.FETCH_IVR_REPORT_TEMPLATES_FAILURE: {
        draft.reportTemplates.data = null;
        draft.reportTemplates.error = true;
        draft.reportTemplates.loading = false;
        break;
      }

      case types.CREATE_IVR_REPORT_TEMPLATE:
      case types.UPDATE_IVR_REPORT_TEMPLATE:
      case types.DELETE_IVR_REPORT_TEMPLATE:
      case types.CLONE_IVR_REPORT_TEMPLATE:
        draft.isLoadingReportTemplate = true;
        break;

      case types.CREATE_IVR_REPORT_TEMPLATE_SUCCESS:
      case types.UPDATE_IVR_REPORT_TEMPLATE_SUCCESS:
      case types.DELETE_IVR_REPORT_TEMPLATE_SUCCESS:
      case types.CLONE_IVR_REPORT_TEMPLATE_SUCCESS:
        draft.isLoadingReportTemplate = false;
        break;

      case types.CREATE_IVR_REPORT_TEMPLATE_FAILURE:
      case types.UPDATE_IVR_REPORT_TEMPLATE_FAILURE:
      case types.DELETE_IVR_REPORT_TEMPLATE_FAILURE:
      case types.CLONE_IVR_REPORT_TEMPLATE_FAILURE:
        draft.isLoadingReportTemplate = false;
        break;

      case types.SEARCH_IVR_REPORT_CALLS: {
        draft.foundReportCalls.error = false;
        draft.foundReportCalls.loading = true;
        break;
      }
      case types.SEARCH_IVR_REPORT_CALLS_SUCCESS: {
        draft.foundReportCalls.error = false;
        draft.foundReportCalls.loading = false;
        draft.foundReportCalls.data = action.payload;
        break;
      }
      case types.SEARCH_IVR_REPORT_CALLS_FAILURE: {
        draft.foundReportCalls.data = null;
        draft.foundReportCalls.error = true;
        draft.foundReportCalls.loading = false;
        break;
      }
      case types.RESET_SEARCH_IVR_REPORT_CALLS: {
        draft.foundReportCalls = INITIAL_STATE.foundReportCalls;
        break;
      }

      case types.SEARCH_IVR_REPORTS: {
        draft.foundReports.error = false;
        draft.foundReports.loading = true;
        break;
      }
      case types.SEARCH_IVR_REPORTS_SUCCESS: {
        draft.foundReports.error = false;
        draft.foundReports.loading = false;
        draft.foundReports.data = action.payload;
        break;
      }
      case types.SEARCH_IVR_REPORTS_FAILURE: {
        draft.foundReports.data = null;
        draft.foundReports.error = true;
        draft.foundReports.loading = false;
        break;
      }

      case types.SEARCH_IVR_REPORT_DASHBOARD: {
        draft.foundReportDashboard.error = false;
        draft.foundReportDashboard.loading = true;
        break;
      }
      case types.SEARCH_IVR_REPORT_DASHBOARD_SUCCESS: {
        draft.foundReportDashboard.error = false;
        draft.foundReportDashboard.loading = false;
        draft.foundReportDashboard.data = action.payload;
        break;
      }
      case types.SEARCH_IVR_REPORT_DASHBOARD_FAILURE: {
        draft.foundReportDashboard.data = null;
        draft.foundReportDashboard.error = true;
        draft.foundReportDashboard.loading = false;
        break;
      }

      case types.SEARCH_IVR_REPORT_ERRORS: {
        draft.foundReportErrors.error = false;
        draft.foundReportErrors.loading = true;
        break;
      }
      case types.SEARCH_IVR_REPORT_ERRORS_SUCCESS: {
        draft.foundReportErrors.error = false;
        draft.foundReportErrors.loading = false;
        draft.foundReportErrors.data = action.payload;
        break;
      }
      case types.SEARCH_IVR_REPORT_ERRORS_FAILURE: {
        draft.foundReportErrors.data = null;
        draft.foundReportErrors.error = true;
        draft.foundReportErrors.loading = false;
        break;
      }

      case types.FETCH_IVR_REPORT_DASHBOARD_TEMPLATES: {
        draft.reportDashboardTemplates.error = false;
        draft.reportDashboardTemplates.loading = true;
        break;
      }
      case types.FETCH_IVR_REPORT_DASHBOARD_TEMPLATES_SUCCESS: {
        draft.reportDashboardTemplates.error = false;
        draft.reportDashboardTemplates.loading = false;
        draft.reportDashboardTemplates.data = action.payload;
        break;
      }
      case types.FETCH_IVR_REPORT_DASHBOARD_TEMPLATES_FAILURE: {
        draft.reportDashboardTemplates.data = null;
        draft.reportDashboardTemplates.error = true;
        draft.reportDashboardTemplates.loading = false;
        break;
      }

      case types.CREATE_IVR_REPORT_DASHBOARD_TEMPLATE_SUCCESS: {
        draft.reportDashboardTemplates.data = [
          ...(state.reportDashboardTemplates.data || []),
          action.payload.data,
        ];
        break;
      }
      case types.UPDATE_IVR_REPORT_DASHBOARD_TEMPLATE_SUCCESS: {
        draft.reportDashboardTemplates.data =
          state.reportDashboardTemplates?.data?.map?.((template) =>
            template._id === action.payload.data._id
              ? action.payload.data
              : template
          );
        break;
      }
      case types.DELETE_IVR_REPORT_DASHBOARD_TEMPLATE_SUCCESS: {
        draft.reportDashboardTemplates.data =
          state.reportDashboardTemplates?.data?.filter?.(
            (template) => template._id !== action.payload.id
          );
        break;
      }

      case types.FETCH_IVR_REPORT_DASHBOARD_FROM_TEMPLATE: {
        draft.reportDashboardFromTemplate.error = false;
        draft.reportDashboardFromTemplate.loading = true;
        break;
      }
      case types.FETCH_IVR_REPORT_DASHBOARD_FROM_TEMPLATE_SUCCESS: {
        draft.reportDashboardFromTemplate.error = false;
        draft.reportDashboardFromTemplate.loading = false;
        draft.reportDashboardFromTemplate.data = action.payload.data;
        break;
      }
      case types.FETCH_IVR_REPORT_DASHBOARD_FROM_TEMPLATE_FAILURE: {
        draft.reportDashboardFromTemplate.data = null;
        draft.reportDashboardFromTemplate.error = true;
        draft.reportDashboardFromTemplate.loading = false;
        break;
      }
      case types.RESET_FETCH_IVR_REPORT_DASHBOARD_FROM_TEMPLATE: {
        draft.reportDashboardFromTemplate =
          INITIAL_STATE.reportDashboardFromTemplate;
        break;
      }

      default:
        return state;
    }
  });
}
