import { format, utcToZonedTime } from 'date-fns-tz';
import {
  ValidationType,
  ValidationTypeExp,
  ValidationTypeExpFlags,
} from './validation-type';

export const isEmail = (str) => {
  const reg = new RegExp(
    ValidationTypeExp[ValidationType.Email],
    ValidationTypeExpFlags[ValidationType.Email]
  );
  return reg.test(str);
};

const formatDateFilter = (date) => {
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date
    .getHours()
    .toString()
    .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
};

export const formatIndicator = (
  filter,
  botId,
  page,
  limit,
  session,
  item_type,
  timezone,
  session_phone_number,
  errorsLogIndicators
) => {
  const obj = {};
  obj.url = `bots=${botId}`;

  const startDate = formatDateFilter(new Date(filter.startDate));
  const endDate = formatDateFilter(new Date(filter.endDate));

  filter.channels.length > 0
    ? (obj.url += `&channels=${filter.channels.toString()}`)
    : '';
  filter.nameTags.length > 0
    ? (obj.url += `&tags=${encodeURIComponent(
        JSON.stringify(filter.nameTags)
      )}`)
    : '';
  filter.variables.length > 0
    ? (obj.url += `&variables=${encodeURIComponent(
        JSON.stringify(filter.variables)
      )}`)
    : '';
  session.length > 0 ? (obj.url += `&session_id=${session}`) : '';

  if (item_type.length > 0) {
    const types = [];
    item_type.forEach((i) => {
      let filter;
      if (i.value === 'JSON API V2') {
        filter = 'APIV2';
      } else if (i.value === 'Script') {
        filter = 'Script';
      } else {
        filter = 'API';
      }
      const item = filter;
      types.push(item);
    });
    obj.url += `&items_types=${types}`;
  }
  obj.url += `&start_date=${encodeURIComponent(startDate)}`;
  obj.url += `&end_date=${encodeURIComponent(endDate)}`;
  obj.url += `&page=${page}`;
  obj.url += `&limit=${limit}`;
  obj.url += `&timezone=${timezone}`;
  obj.url += `&session_phone_number=${session_phone_number}`;
  obj.url += `&errorsLogIndicators=${errorsLogIndicators}`;

  return obj;
};

export const getClientTimezoneOffset = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const formatDate = (date, forma, timeZone) => {
  const offSet = timeZone || getClientTimezoneOffset();
  return format(utcToZonedTime(date, offSet), forma, {
    timeZone: offSet,
  });
};

export const treatMessage = (message, channel = 'webchat') => {
  if (message === '**********') return '✱✱✱✱✱';
  const linkRegex =
    /((http:\/\/|https:\/\/|ftp:\/\/)|(www.))+([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/gi;
  const strongRegex = {
    webchat: /(?<!\*)\*{1,2}(.*?[^*])\*{1,2}(?!\*)/g,
    whatsapp: /(?<!\*)\*{1,2}(.*?[^*])\*{1,2}(?!\*)/g,
  };
  const breaklineRegex = /\n/g;

  let messageTreated = message?.replace(linkRegex, (str) => {
    if (!str.match(/http/)) {
      return `<a target="_blank" href="http://${str}">${str}</a>`;
    }
    return `<a target="_blank" href="${str}">${str}</a>`;
  });

  messageTreated = messageTreated?.replace(strongRegex[channel], (str) => {
    return `<strong>${str.substr(1, str.length - 2)}</strong>`;
  });

  messageTreated = messageTreated?.replace(breaklineRegex, '<br/>');

  let validationMessageHTML = false;

  const validatedTag = ['<html', '<script', '<style', '<table'];

  messageTreated = messageTreated || [];
  validationMessageHTML = validatedTag.some((tag) =>
    messageTreated.includes(tag)
  );

  let createReturnHTML = '';

  if (messageTreated.includes('<finaltag />')) {
    createReturnHTML = `<xmp style="white-space: break-spaces;">${
      messageTreated.split('<finaltag />')[0]
    }</xmp>`;

    createReturnHTML += messageTreated.split('<finaltag />')[1];
  }

  return validationMessageHTML ? createReturnHTML : messageTreated;
};

const baseUrl = 'https://api.rd.services';

const RD_STATION_APP = {
  dev: {
    clientId: 'bd2a1408-0251-46be-8392-a4f54d5b17eb',
    partnersUrl: 'https://6ccb-45-160-89-222.ngrok.io/partner/rd/code',
  },
  homolog: {
    clientId: 'afccc1c9-1cdb-459a-b340-7adf7c71e540',
    partnersUrl:
      'https://api-partners.hmlbots.digitalcontact.cloud/partner/rd/code',
  },
  prod: {
    clientId: 'ebb385db-d304-4337-ae12-7bb72e50fbb8', // TODO: mudar
    partnersUrl: 'https://api-partners.boteria.com.br/partner/rd/code',
  },
};

const currentRdStationApp =
  RD_STATION_APP[process.env.REACT_APP_RD_STATION?.toLocaleLowerCase()];

const rdStationInfos = {
  partnersUrl: currentRdStationApp?.partnersUrl,
  authUrl: `${baseUrl}/auth/dialog`,
  clientId: currentRdStationApp?.clientId,
};

export const rdStationLogin = () => {
  try {
    const { authUrl, clientId, partnersUrl } = rdStationInfos;
    // const callbackUrl = encodeURIComponent(partnersUrl);
    const url = `${authUrl}?client_id=${clientId}&redirect_uri=${partnersUrl}`;
    window.open(url, '_blank');
  } catch (error) {
    console.error(error);
    return JSON.error('Erro', 'Falha ao tentar fazer login');
  }
};

export const isRedator = (selectedCompany) => {
  return selectedCompany.claim?.name === 'Redator';
};

export const isViewer = (selectedCompany) => {
  return selectedCompany.claim?.name === 'Viewer';
};

export const isRedatorOrViewer = (selectedCompany) => {
  if (
    selectedCompany?.claim?.name === 'Redator' ||
    selectedCompany?.claim?.name === 'Viewer'
  ) {
    return true;
  }
  return false;
};

export const isRootOrCs = (selectedCompany) => {
  if (
    selectedCompany?.claim?.name.toLocaleLowerCase() === 'root' ||
    selectedCompany?.claim?.name.toLocaleLowerCase() === 'cs'
  ) {
    return true;
  }
  return false;
};

export const isRootUser = (selectedCompany) => {
  return selectedCompany?.claim?.name?.toLocaleLowerCase?.() === 'root';
};

export const isAdminUser = (selectedCompany) => {
  return selectedCompany?.claim?.name?.toLocaleLowerCase?.() === 'admin';
};

export const isRootOrAdmin = (loggedUser) => {
  return ['root', 'admin'].includes(
    loggedUser?.selectedCompany?.claim?.name?.toLocaleLowerCase?.()
  );
};

export const applyMaxLength = (string) => {
  if (string?.length > 20) {
    string = `${string.substring(0, 17)}...`;
  }
  return string;
};
