import styled from 'styled-components';

export const Container = styled.span`
  display: inline-block;
  font-size: 12px;
  font-weight: 400;

  &.error {
    color: var(--semantic-red-400-light);
  }
`;
