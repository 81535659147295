const Types = {
  // other ia

  IA_CREATE_PROVIDER: '@intelligence/IA_CREATE_PROVIDER',
  IA_CREATE_PROVIDER_SUCCESS: '@intelligence/IA_CREATE_PROVIDER_SUCCESS',
  IA_LIST_PROVIDER: '@intelligence/IA_LIST_PROVIDER',
  IA_LIST_PROVIDER_SUCCESS: '@intelligence/IA_LIST_PROVIDER_SUCCESS',
  IA_PLATFORM: '@intelligence/IA_PLATFORM',
  IA_PLATFORM_SUCCESS: '@intelligence/IA_PLATFORM_SUCCESS',
  IA_TEST_VALIDATE_TOKEN_SUCCESS:
    '@intelligence/IA_TEST_VALIDATE_TOKEN_SUCCESS',
  IA_TEST_VALIDATE_TOKEN: '@intelligence/IA_TEST_VALIDATE_TOKEN',
  IA_TEST_VALIDATE_LEX_CREDENTIALS:
    '@intelligence/IA_TEST_VALIDATE_LEX_CREDENTIALS',
  IA_UPDATE_APPLICATION: '@intelligence/IA_UPDATE_APPLICATION',
  IA_UPDATE_APPLICATION_SUCCESS: '@intelligence/IA_UPDATE_APPLICATION_SUCCESS',

  IA_PLATFORM_INFO: '@intelligence/IA_PLATFORM_INFO',
  IA_PLATFORM_INFO_SUCCESS: '@intelligence/IA_PLATFORM_INFO_SUCCESS',

  IA_LIST_INTENTS: '@intelligence/IA_LIST_INTENTS',
  IA_LIST_INTENTS_SUCCESS: '@intelligence/IA_LIST_INTENTS_SUCCESS',
  IA_DELETE_INTENTS: '@intelligence/IA_DELETE_INTENTS',
  IA_CREATE_INTENTS: '@intelligence/IA_CREATE_INTENTS',

  IA_LIST_SAMPLES_INTENT: '@intelligence/IA_LIST_SAMPLES_INTENT',
  IA_LIST_SAMPLES_INTENT_SUCCESS:
    '@intelligence/IA_LIST_SAMPLES_INTENT_SUCCESS',
  IA_LIST_SAMPLES: '@intelligence/IA_LIST_SAMPLES',
  IA_LIST_SAMPLES_SUCCESS: '@intelligence/IA_LIST_SAMPLES_SUCCESS',
  IA_SAVE_SAMPLE: '@intelligence/IA_SAVE_SAMPLE',
  IA_SAVE_SAMPLE_SUCCESS: '@intelligence/IA_SAVE_SAMPLE_SUCCESS',
  IA_DELETE_SAMPLES: '@intelligence/IA_DELETE_SAMPLES',
  IA_LIST_ALL_SAMPLES: '@intelligence/IA_LIST_ALL_SAMPLES',
  IA_LIST_ALL_SAMPLES_SUCCESS: '@intelligence/IA_LIST_ALL_SAMPLES_SUCCESS',

  IA_LIST_CONDITIONS: '@intelligence/IA_LIST_CONDITIONS',
  IA_LIST_CONDITIONS_SUCCESS: '@intelligence/IA_LIST_CONDITIONS_SUCCESS',
  IA_UPDATE_CONDITIONS: '@intelligence/IA_UPDATE_CONDITIONS',
  IA_UPDATE_CONDITIONS_SUCCESS: '@intelligence/IA_UPDATE_CONDITIONS_SUCCESS',
  IA_CREATE_CONDITIONS: '@intelligence/IA_CREATE_CONDITIONS',
  IA_CREATE_CONDITIONS_SUCCESS: '@intelligence/IA_CREATE_CONDITIONS_SUCCESS',
  IA_DELETE_CONDITIONS: '@intelligence/IA_DELETE_CONDITIONS',
  IA_DELETE_CONDITIONS_SUCCESS: '@intelligence/IA_DELETE_CONDITIONS_SUCCESS',

  IA_LIST_ENTITY: '@intelligence/IA_LIST_ENTITY',
  IA_LIST_ENTITY_SUCCESS: '@intelligence/IA_LIST_ENTITY_SUCCESS',
  IA_CREATE_ENTITIES: '@intelligence/IA_CREATE_ENTITIES',
  IA_CREATE_ENTITIES_SUCCESS: '@intel/IA_CREATE_ENTITIES_SUCCESS',
  IA_LIST_ENTITIES: '@intelligence/IA_LIST_ENTITIES',
  IA_LIST_ENTITIES_SUCCESS: '@intel/IA_LIST_ENTITIES_SUCCESS',
  IA_DELETE_ENTITIES: '@intelligence/IA_DELETE_ENTITIES',
  IA_DELETE_ENTITIES_SUCCESS: '@intelligence/IA_DELETE_ENTITIES_SUCCESS',
  IA_DELETE_ENTITIES_OBJ: '@intelligence/IA_DELETE_ENTITIES_OBJ',
  IA_DELETE_ENTITIES_OBJ_SUCCESS:
    '@intelligence/IA_DELETE_ENTITIES_OBJ_SUCCESS',
  IA_DELETE_ENTITIES_SYN: '@intelligence/IA_DELETE_ENTITIES_SYN',
  IA_DELETE_ENTITIES_SYN_SUCCESS:
    '@intelligence/IA_DELETE_ENTITIES_SYN_SUCCESS',

  // curation
  IA_CURATION_BOT_LIST_MESSAGES: '@intelligence/IA_CURATION_BOT_LIST_MESSAGES',
  IA_CURATION_BOT_LIST_MESSAGES_SUCCESS:
    '@intelligence/IA_CURATION_BOT_LIST_MESSAGES_SUCCESS',

  IA_CURATION_BOT_SAVE_APRIMORAMENTOS:
    '@intelligence/IA_CURATION_BOT_SAVE_APRIMORAMENTOS',
  IA_CURATION_BOT_SAVE_APRIMORAMENTOS_SUCCESS:
    '@intelligence/IA_CURATION_BOT_SAVE_APRIMORAMENTOS_SUCCESS',

  IA_CURATION_BOT_SET_APRIMORAMENTOS:
    '@intelligence/IA_CURATION_BOT_SET_APRIMORAMENTOS',
  IA_CURATION_BOT_SET_APRIMORAMENTOS_SUCCESS:
    '@intelligence/IA_CURATION_BOT_SET_APRIMORAMENTOS_SUCCESS',

  IA_CURATION_BOT_APRIMORAMENTOS_DELETE:
    '@intelligence/IA_CURATION_BOT_APRIMORAMENTOS_DELETE',
  IA_CURATION_BOT_APRIMORAMENTOS_DELETE_SUCCESS:
    '@intelligence/IA_CURATION_BOT_APRIMORAMENTOS_DELETE_SUCCESS',

  IA_CURATION_BOT_APRIMORAMENTOS_CLEAR:
    '@intelligence/IA_CURATION_BOT_APRIMORAMENTOS_CLEAR',
  IA_CURATION_BOT_APRIMORAMENTOS_CLEAR_SUCCESS:
    '@intelligence/IA_CURATION_BOT_APRIMORAMENTOS_CLEAR_SUCCESS',

  IA_CURATION_BOT_ACCEPT_MESSAGE:
    '@intelligence/IA_CURATION_BOT_ACCEPT_MESSAGE',
  IA_CURATION_BOT_ACCEPT_MESSAGE_SUCCESS:
    '@intelligence/IA_CURATION_BOT_ACCEPT_MESSAGE_SUCCESS',

  IA_CURATION_BOT_IGNORE_MESSAGE:
    '@intelligence/IA_CURATION_BOT_IGNORE_MESSAGE',
  IA_CURATION_BOT_IGNORE_MESSAGE_SUCCESS:
    '@intelligence/IA_CURATION_BOT_IGNORE_MESSAGE_SUCCESS',
  IA_CREATE_WIT: '@intelligence/IA_CREATE_WIT',
  IA_CREATE_WIT_SUCCESS: '@intelligence/IA_CREATE_WIT_SUCCESS',
  IA_LIST_WIT: '@intelligence/IA_LIST_WIT',
  IA_LIST_WIT_SUCCESS: '@intelligence/IA_LIST_WIT_SUCCESS',
  IA_BUILD_LEX: '@intelligence/IA_BUILD_LEX',
  IA_PUBLISH_LEX: '@intelligence/IA_PUBLISH_LEX',
  IA_PUBLISH_LEX_SUCCESS: '@intelligence/IA_PUBLISH_LEX_SUCCESS',
  RESET_VALIDATE_TOKEN: '@intelligence/RESET_VALIDATE_TOKEN',
};

export default Types;
