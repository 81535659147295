import { takeLatest, call, all, put } from 'redux-saga/effects';
import { commonLoadingStart, commonLoadingFinish } from '../common/actions';

import { toast } from '~/components/CustomToast';
import i18n from '../../../translations/i18n';

import api from '../../../services/api.ia';

import * as actions from './actions';
import types from './types';

export function* iaPreview({ payload }) {
  const { token, message, botAliasId } = payload;
  const platform =
    payload.platform === 'boteria' || payload.platform === 'wit'
      ? 'wit'
      : payload.platform;
  const url = `/${platform}/message`;

  yield put(commonLoadingStart());

  try {
    const { data } = yield call(api.post, url, {
      token,
      message,
      botAliasId,
    });
    yield put(actions.iaPreviewSuccess({ data }));
  } catch (e) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_ia_preview'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([takeLatest(types.IA_PREVIEW, iaPreview)]);
