import React from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';

import * as S from './styles';

const LengthCounter = ({ value = '', maxLength, ...rest }) => {
  const sanitizeValue = value?.replace?.(/{(.*?)}/g, '');
  const length = sanitizeValue?.length || 0;

  return (
    <S.Container className={cc({ error: length > maxLength })} {...rest}>
      {`${length}/${maxLength}`}
    </S.Container>
  );
};

LengthCounter.propTypes = {
  value: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
};

export default LengthCounter;
