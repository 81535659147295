import { takeLatest, put, all, call, delay } from 'redux-saga/effects';

import i18n from '~/translations/i18n';

import api from '~/services/api';
import apiIa from '~/services/intelligence/api';
import { toast } from '~/components/CustomToast';
import history from '~/services/history';

import {
  commonLoadingStart,
  commonLoadingFinish,
  commonChangingFinish,
} from '../common/actions';
import * as actions from './actions';
import * as actionsBot from '../bots/actions';
import types from './types';

export function* createIAProvider(action) {
  const { payload } = action;

  const TOKEN_WIT = 'TCMOK3OHDYKTFC2BFFUVGMNF5PT6JVII';
  const TOKEN_LUIS = '36fe2c5ac7bf456fbc30bb13f13a710a';

  try {
    yield put(commonLoadingStart());

    const findPlatform = payload.bot.ia?.platforms?.find(
      (element) => element.platform === payload.platform
    );

    const platform =
      action.payload.platform === 'boteria' || action.payload.platform === 'wit'
        ? 'wit'
        : action.payload.platform;

    const dataUpdate = {
      name: payload.bot._id,
      token: payload.platform === 'luis' ? TOKEN_LUIS : TOKEN_WIT,
      language: payload.language,
    };

    if (!findPlatform) {
      const { data } = yield call(
        apiIa.post,
        `/${platform}/application`,
        dataUpdate
      );

      yield put(actions.createIAProviderSuccess(data));
      yield put(actions.iaPlatformSuccess(payload.platform));

      const response = yield call(
        api.put,
        `/v3/bots/update/${payload.bot._id}`,
        {
          newTokenIa: data.access_token,
          iAProviderEnable: true,
          ia: {
            platforms: [
              {
                token: data.access_token,
                platform: payload.platform,
                isActive: true,
              },
            ],
          },
          iaEnabled: true,
        }
      );

      yield put(actionsBot.updateBotSuccess({ data: response.data.bot }));
      history.push(`/bots/${payload.bot._id}/ia/nlu`);
      toast.success(
        i18n.t('success.success'),
        i18n.t('bots.ia.provider.create_platform_success')
      );
    } else {
      const response = yield call(
        api.put,
        `/v3/bots/update/${payload.bot._id}`,
        (payload?.bot?.newTokenIa || payload?.bot?.currentTokenIa) ===
          findPlatform.token
          ? { iAProviderEnable: false }
          : { newTokenIa: findPlatform.token }
      );

      yield put(actionsBot.updateBotSuccess({ data: response.data.bot }));

      yield put(actions.iaPlatformSuccess(payload.platform));

      if (
        (payload?.bot?.newTokenIa || payload?.bot?.currentTokenIa) ===
        findPlatform.token
      ) {
        toast.success(
          i18n.t('success.success'),
          i18n.t('bots.ia.provider.disable_platform')
        );
      } else {
        toast.success(
          i18n.t('success.success'),
          i18n.t('bots.ia.provider.enable_platform')
        );
      }
    }
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.filter_error'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* listIAProvider(action) {
  const { payload } = action;
  try {
    const platform =
      payload.platform === 'boteria' || payload.platform === 'wit'
        ? 'wit'
        : payload.platform;

    if (platform) {
      yield put(commonLoadingStart());
      const { data } = yield call(
        apiIa.get,
        `/${platform}/application?token=${payload.token}&botAliasId=${payload.botAliasId}`
      );

      yield put(actions.listIAProviderSuccess(data));
    }
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.filter_error'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* createWit(action) {
  const { payload } = action;
  try {
    yield put(commonLoadingStart());

    const findL = payload?.data?.ia?.platforms.find(
      (element) => element.platform === payload.platform
    );

    if (!findL) {
      yield put(actions.iaPlatformSuccess(payload));

      yield call(api.put, `/v3/bots/update/${payload.botid}`, {
        newTokenIa: payload.credentials.token,
        ia: {
          platforms: [
            {
              token: payload.credentials.token,
              platform: 'wit',
              isActive: true,
            },
          ],
        },
        iaEnabled: true,
      });
      yield put(actions.iaPlatformSuccess(payload));
      yield put(actions.resetValidateToken(null));
      yield put(actionsBot.fetchBotRequest({ id: payload.botid }));
      history.push(`/bots/${payload.botid}/ia/nlu`);
      toast.success(
        i18n.t('success.success'),
        i18n.t('bots.ia.provider.create_platform_success')
      );
    } else {
      const response = yield call(
        api.put,
        `/v3/bots/update/${payload.botid}`,
        payload.data.newTokenIa === findL.token
          ? { iaEnabled: false }
          : { newTokenIa: findL.token, iaEnabled: true }
      );
      yield put(actionsBot.updateBotSuccess(response.data));
      yield put(actions.iaPlatformSuccess(payload));

      if (payload.data.newTokenIa === findL.token) {
        toast.success(
          i18n.t('success.success'),
          i18n.t('bots.ia.provider.disable_platform')
        );
      } else {
        toast.success(
          i18n.t('success.success'),
          i18n.t('bots.ia.provider.enable_platform')
        );
      }
    }
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.filter_error'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* validateToken(action) {
  const { payload } = action;
  const platform =
    payload.platform === 'boteria' || payload.platform === 'wit'
      ? 'wit'
      : payload.platform;
  try {
    yield put(commonLoadingStart());
    const { data } = yield call(
      apiIa.get,
      `/${platform}/test?token=${payload.token}`
    );

    yield put(actions.testValidateTokenSuccess(data));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.filter_error'));
    yield put(actions.testValidateTokenSuccess({ validate: false }));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* validateLexCredentials(action) {
  const { lexBotId, botId } = action.payload.data;
  try {
    yield put(commonLoadingStart());
    const { data } = yield call(apiIa.post, `lex/application`, {
      token: lexBotId,
      name: botId,
    });
    yield put(actions.testValidateTokenSuccess(data));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.filter_error'));
    yield put(actions.testValidateTokenSuccess(false));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateApplication(action) {
  const { payload } = action;
  try {
    yield put(commonLoadingStart());

    const { data } = yield call(apiIa.post, `/v1/ia/application/update`, {
      token: payload.data.token,
      tokenIA: payload.data.tokenIA,
      language: payload.data.language.value,
    });

    yield put(actions.updateApplicationSuccess(data.validate));
    yield put(actions.resetValidateToken(null));
    toast.success(
      i18n.t('bots.ia.ia_updated'),
      i18n.t('bots.ia.ia_credentials_updated')
    );
    yield put(commonChangingFinish());
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.filter_error'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* listIntents(action) {
  const { payload } = action;
  const platform =
    payload.platform === 'boteria' || payload.platform === 'wit'
      ? 'wit'
      : payload.platform;
  try {
    const { data } = yield call(
      apiIa.get,
      `/${platform}/intent?token=${payload.token}`
    );
    yield put(actions.listIntentsSuccess(data));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_list_ia_intents'));
  }
}

export function* createIntents(action) {
  const { payload } = action;
  const { token, name, sentences, isCreate, originSamples } = payload;
  const platform =
    payload.platform === 'boteria' || payload.platform === 'wit'
      ? 'wit'
      : payload.platform;

  const samples = sentences.map((sentence) => sentence.text);

  try {
    yield put(commonLoadingStart());
    if (isCreate === true) {
      yield call(apiIa.post, `/${platform}/intent`, { token, name });
    }

    if (sentences.length >= 1) {
      const { data: sent } = yield call(apiIa.post, `/${platform}/samples`, {
        token,
        samples,
        intent: name,
      });

      if (!sent) {
        const errorMessage = i18n.t('error.error_example_already_exists');
        const errorSamples = `- ${sent.sampleError.text}`;

        if (originSamples?.intent && originSamples?.intent.length === 0) {
          yield call(apiIa.post, `/v1/ia/intent/delete`, { ...payload });
        }

        toast.error(errorMessage, errorSamples);
        throw new Error('Invalid or missing params');
      }
    }

    const response = yield call(
      apiIa.get,
      `/${platform}/intent?token=${payload.token}`
    );

    yield put(actions.listIntentsSuccess(response.data));

    yield put(actions.listSamplesSuccess(null));

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_create_ia_intents')
    );
    yield put(commonChangingFinish());
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_create_ia_intents'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* deleteIntents(action) {
  const { payload } = action;
  const platform =
    payload.platform === 'boteria' || payload.platform === 'wit'
      ? 'wit'
      : payload.platform;
  try {
    yield put(commonLoadingStart());
    yield call(
      apiIa.delete,
      `/${platform}/intent?token=${payload.token}&name=${payload.name}`
    );

    const { data } = yield call(
      apiIa.get,
      `/${platform}/intent?token=${payload.token}`
    );

    yield put(actions.listIntentsSuccess(data));
    yield put(actions.listSamplesSuccess(null));

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_delete_ia_intents')
    );
  } catch (error) {
    toast.warn(
      i18n.t('warning.warning_notice'),
      i18n.t('warning.delete_processing')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* listSamples() {
  try {
    yield put(actions.listSamplesSuccess(null));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_list_ia_samples'));
  }
}

export function* listAllSamples(action) {
  yield put(commonLoadingStart());
  const { payload } = action;
  const platform =
    payload.platform === 'boteria' || payload.platform === 'wit'
      ? 'wit'
      : payload.platform;
  try {
    const { data } = yield call(
      apiIa.get,
      `/${platform}/samples?token=${payload.token}`
    );

    yield put(actions.listAllSamplesSuccess(data.body.data));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_list_ia_samples'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* listSamplesIntent(action) {
  const { payload } = action;
  const platform =
    payload.platform === 'boteria' || payload.platform === 'wit'
      ? 'wit'
      : payload.platform;
  try {
    const { data } = yield call(
      apiIa.get,
      `/${platform}/samples?token=${payload.token}&intent=${payload.value}`
    );

    yield put(actions.listSamplesIntentSuccess(data.body));

    if (payload.callback) {
      yield payload.callback(data[0]);
    }
  } catch (error) {
    // toast.error(
    //   i18n.t('error.error'),
    //   i18n.t('error.error_list_intent_samples')
    // );
  }
}

export function* deleteSamples(action) {
  const { payload } = action;
  const platform =
    payload.platform === 'boteria' || payload.platform === 'wit'
      ? 'wit'
      : payload.platform;
  try {
    yield put(commonLoadingStart());

    yield call(apiIa.post, `/${platform}/deleteSamples`, {
      ...payload,
      samples: [payload.sentence],
    });

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_delete_intent_sample')
    );
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_delete_ia_samples'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getIaInfo(action) {
  const { payload } = action;
  const platform =
    payload.platform === 'boteria' || payload.platform === 'wit'
      ? 'wit'
      : payload.platform;
  try {
    if (platform) {
      const { data } = yield call(
        apiIa.get,
        `/${platform}/application?token=${payload.token}&botAliasId=${payload.botAliasId}&status=${payload.status}`
      );
      yield put(actions.getIaInfoSuccess(data));
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}
}

export function* listConditions(action) {
  const { payload } = action;
  // yield put(commonLoadingStart());

  try {
    const { data } = yield call(
      api.post,
      '/v3/conditions/fetch?key=kgjdhURyashsJKSkd2kkd98Yf7',
      { botId: payload.botId }
    );

    yield put(actions.listConditionsSuccess(JSON.parse(data)));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_list_ia_conditions')
    );
  } // finally {
  //   put(commonLoadingFinish());
  // }
}

export function* updateCondition(action) {
  const { payload } = action;
  try {
    yield put(commonLoadingStart());
    if (payload.data.platform === 'lex') {
      const response = yield call(
        api.post,
        '/v3/conditions/fetch?key=kgjdhURyashsJKSkd2kkd98Yf7',
        { botId: payload.data.bot }
      );
      const data = JSON.parse(response.data);
      const conditionsFound = data.filter(
        (element) =>
          element.entity === payload.data.entity &&
          element.intent === payload.data.intent
      );

      if (conditionsFound.length === 1) {
        yield call(apiIa.put, `/${payload.data.platform}/condition`, {
          token: payload.data.token,
          intent: payload.data.intent,
          entity: payload.data.entity,
          conditionId: payload.data.conditionId,
          removeEntity:
            payload.data.entity === '' &&
            payload.data.originalEntity.entity !== '',
          originalEntity: payload.data.originalEntity.entity,
        });
      } else {
        const response = yield call(
          apiIa.post,
          `/${payload.data.platform}/condition`,
          {
            token: payload.data.token,
            intent: payload.data.intent,
            entity: payload.data.entity,
            removeEntity:
              payload.data.entity === '' &&
              payload.data.originalEntity.entity !== '',
            originalEntity: payload.data.originalEntity.entity,
          }
        );
        payload.data.conditionId = response.data?.conditionId;
      }
    }

    const { data } = yield call(
      api.put,
      `/v3/conditions/${payload?.data._id}`,
      {
        ...payload?.data,
      }
    );

    yield put(actions.updateConditionSuccess({ ...data }));
    yield put(
      actionsBot.updateBotLastUpdateSuccess({
        countUpdatesNoPublish: data?.countUpdatesNoPublish,
      })
    );
    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_edit_ia_condition')
    );
    yield put(commonChangingFinish());
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_edit_ia_condition'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* createCondition(action) {
  const { payload } = action;
  try {
    yield put(commonLoadingStart());
    if (payload.data.platform === 'lex' && payload.data.entity !== '') {
      const response = yield call(
        api.post,
        '/v3/conditions/fetch?key=kgjdhURyashsJKSkd2kkd98Yf7',
        { botId: payload.data.bot }
      );
      const data = JSON.parse(response.data);
      const conditionsFound = data.filter(
        (element) =>
          element.entity === payload.data.entity &&
          element.intent === payload.data.intent
      );

      if (conditionsFound.length === 0) {
        const response = yield call(
          apiIa.post,
          `/${payload.data.platform}/condition`,
          {
            token: payload.data.token,
            intent: payload.data.intent,
            entity: payload.data.entity,
          }
        );
        payload.data.conditionId = response.data?.conditionId;
      } else {
        payload.data.conditionId = conditionsFound[0].conditionId;
      }
    }

    const createResponse = yield call(api.post, `/v3/conditions`, {
      ...payload.data,
    });

    const responseFormated = {
      _doc: createResponse.data,
      countUpdatesNoPublish: createResponse.data.countUpdatesNoPublish,
    };

    yield put(actions.createConditionSuccess(createResponse.data));
    yield put(actionsBot.updateBotLastUpdateSuccess(responseFormated));

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_create_ia_condition')
    );
    yield put(commonChangingFinish());
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_create_ia_condition')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* deleteCondition(action) {
  const { payload } = action;
  try {
    yield put(commonLoadingStart());

    if (payload.platform === 'lex' && payload.conditionToDelete.entity !== '') {
      const response = yield call(
        api.post,
        '/v3/conditions/fetch?key=kgjdhURyashsJKSkd2kkd98Yf7',
        { botId: payload.botId }
      );
      const data = JSON.parse(response.data);
      const conditionsFound = data.filter(
        (element) =>
          element.entity === payload.conditionToDelete.entity &&
          element.intent === payload.conditionToDelete.intent
      );

      if (conditionsFound.length === 1) {
        yield call(
          apiIa.delete,
          `/${payload.platform}/condition?token=${payload.token}&conditionId=${payload.conditionToDelete.conditionId}&intent=${payload.conditionToDelete.intent}`
        );
      }
    }

    const { data } = yield call(api.delete, `/v3/conditions/${payload._id}`);
    yield put(actions.deleteConditionSuccess(payload));
    yield put(actionsBot.updateBotLastUpdateSuccess(data));

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_delete_ia_condition')
    );
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_delete_ia_samples'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* createEntities(action) {
  const { payload } = action;
  const platform =
    payload.platform === 'boteria' || payload.platform === 'wit'
      ? 'wit'
      : payload.platform;
  try {
    yield put(commonLoadingStart());

    if (!payload.haveEntity) {
      yield call(apiIa.post, `/${platform}/entity`, {
        name: payload.entity,
        token: payload.token,
      });
    }

    yield call(apiIa.put, `/${platform}/entity`, payload);

    const { data } = yield call(
      apiIa.get,
      `/${platform}/entity?token=${payload.token}`
    );
    yield put(actions.listEntitiesSuccess(data.entities));

    toast.success(
      i18n.t('success.success'),
      payload.createEntity
        ? i18n.t('success.success_create_ia_entity')
        : i18n.t('success.success_edit_ia_entity')
    );
    yield put(commonChangingFinish());
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_create_ia_entities')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* listEntities(action) {
  const { payload } = action;
  const platform =
    payload.platform === 'boteria' || payload.platform === 'wit'
      ? 'wit'
      : payload.platform;
  try {
    if (platform === 'lex') {
      yield delay(1000);
    }
    yield put(commonLoadingStart());
    const { data } = yield call(
      apiIa.get,
      `/${platform}/entity?token=${payload.token}`
    );
    yield put(actions.listEntitiesSuccess(data.entities));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_list_ia_entities'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* listEntity(action) {
  const { payload } = action;
  const platform =
    payload.platform === 'boteria' || payload.platform === 'wit'
      ? 'wit'
      : payload.platform;

  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      apiIa.get,
      `/${platform}/entity?token=${payload.token}`
    );

    yield put(actions.listEntitySuccess(data.entities));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_list_ia_entity'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* deleteEntities(action) {
  const { payload } = action;
  const platform =
    payload.platform === 'boteria' || payload.platform === 'wit'
      ? 'wit'
      : payload.platform;
  try {
    yield put(commonLoadingStart());

    yield call(
      apiIa.delete,
      `/${platform}/entity?token=${payload.token}&name=${payload.name}`
    );
    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_delete_ia_entity')
    );
  } catch (e) {
    toast.warn(
      i18n.t('warning.warning_notice'),
      i18n.t('warning.delete_processing')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* deleteEntitiesObj(action) {
  const { payload } = action;
  try {
    yield put(commonLoadingStart());

    yield call(apiIa.post, `/v1/ia/entity/object/delete`, payload);

    const { data } = yield call(apiIa.post, `/v1/ia/entity/get`, {
      token: payload.token,
    });
    yield put(actions.listEntitiesSuccess(data));

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_delete_ia_object_entity')
    );
  } catch (error) {
    toast.warn(
      i18n.t('warning.warning_notice'),
      i18n.t('warning.delete_processing')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* deleteEntitiesSyn(action) {
  const { payload } = action;
  try {
    yield put(commonLoadingStart());

    yield call(apiIa.post, `/v1/ia/entity/object/synonym/delete`, payload);

    const { data } = yield call(apiIa.post, `/v1/ia/entity/get`, {
      token: payload.token,
      name: payload.name,
    });
    yield put(actions.listEntitySuccess(data));

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_delete_ia_synonym_object')
    );
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_delete_ia_synonym_object')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* listWit(action) {
  const { payload } = action;
  const platform =
    payload.platform === 'boteria' || payload.platform === 'wit'
      ? 'wit'
      : payload.platform;
  try {
    if (platform) {
      yield put(commonLoadingStart());

      const { data } = yield call(
        apiIa.get,
        `/${platform}/application?token=${payload.token}&botAliasId=${payload.botAliasId}`
      );

      yield put(actions.listWitSuccess(data));
    }
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.filter_error'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

// curations
export function* listCurationMessages(action) {
  const { payload } = action;
  try {
    yield put(commonLoadingStart());
    if (!(payload.bot && payload.count))
      throw new Error('Invalid or missing params');

    let url = `/v3/bots/curation/?bot=${payload.bot}&count=${payload.count}`;
    if (payload.query) url += `&query=${payload.query}`;
    if (payload.processed) url += `&processed=true`;
    if (payload.status) url += `&status=${payload.status}`;
    if (payload.start_date) url += `&start_date=${payload.start_date}`;
    if (payload.end_date) url += `&end_date=${payload.end_date}`;

    const { data } = yield call(api.get, url);
    yield put(actions.listCurationMessagesSuccess(data.messages));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_list_ia_curation'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* saveAprimoramentos(action) {
  const { enhancements, options } = action.payload;
  const platform =
    action.payload.platform === 'boteria' || action.payload.platform === 'wit'
      ? 'wit'
      : action.payload.platform;
  try {
    yield put(commonLoadingStart());
    for (let i = 0; i < enhancements.length; ++i) {
      const { payload, type } = enhancements[i];
      switch (type) {
        case 'ADD_SAMPLE_INTENT':
          yield call(apiIa.post, `/${platform}/samples`, {
            token: payload.token,
            samples: [payload.text],
            intent: payload.name,
          });
          break;
        case 'ADD_SAMPLE_ENTITY':
          yield call(apiIa.post, `/${platform}/keywords`, {
            token: payload.token,
            entity: payload.name,
            keywords: [{ keyword: payload.text, synonyms: [payload.text] }],
          });
          break;
        case 'ADD_INTENT':
          yield call(apiIa.post, `/${platform}/intent`, {
            token: payload.token,
            name: payload.name,
          });
          yield call(apiIa.post, `/${platform}/samples`, {
            token: payload.token,
            samples: [payload.text],
            intent: payload.name,
          });
          break;
        case 'ADD_ENTITY':
          yield call(apiIa.post, `/${platform}/entity`, {
            token: payload.token,
            name: payload.name,
          });
          if (platform !== 'lex') {
            yield call(apiIa.post, `/${platform}/keywords`, {
              token: payload.token,
              entity: payload.name,
              keywords: [{ keyword: payload.text, synonyms: [payload.text] }],
            });
          } else {
            yield call(apiIa.put, `/${platform}/entity`, {
              token: payload.token,
              name: payload.name,
              keywords: [{ keyword: payload.text, synonyms: [payload.text] }],
            });
          }
          break;
        default:
          break;
      }
    }

    yield call(
      api.get,
      `/v3/bots/curation/accept/?bot=${options.bot}&message=${options.message}`
    );

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_ia_save_improvement')
    );
  } catch (err) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_ia_improvement'));
  } finally {
    yield put(commonLoadingFinish());
    yield options.callback();
  }
}

export function* ignoreCurationMessage(action) {
  const { payload } = action;

  try {
    yield put(commonLoadingStart());
    const platform =
      action.payload.platform === 'boteria' || action.payload.platform === 'wit'
        ? 'wit'
        : action.payload.platform;

    if (!(payload.bot && payload.messageId))
      throw new Error(i18n.t('error.error_missing_params'));

    const url = `/v3/bots/curation/ignore/?bot=${payload.bot}&message=${payload.messageId}`;

    yield call(apiIa.post, `/${platform}/samples`, {
      token: payload.token,
      samples: [payload.messageId],
      intent: '',
    });

    yield call(api.get, url);
    yield put(actions.ignoreCurationMessageSuccess(payload));

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_ia_ignore_curation_message')
    );
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_ia_gnore_curation_message')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* acceptCurationMessage(action) {
  const { payload } = action;
  try {
    yield put(commonLoadingStart());

    if (!(payload.bot && payload.message))
      throw new Error(i18n.t('error.error_missing_params'));

    const url = `/v3/bots/curation/accept/?bot=${payload.bot}&message=${payload.message}`;

    const { data } = yield call(api.get, url);
    yield put({
      type: types.IA_CURATION_BOT_ACCEPT_MESSAGE_SUCCESS,
      payload: data.result,
    });

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_ia_accept_curation_message')
    );
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_ia_accept_curation_message')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* publishLex(action) {
  const { token, botAliasId, botId } = action.payload;

  try {
    yield put(commonLoadingStart());
    const data = yield call(apiIa.post, `/lex/publish`, {
      token,
      botAliasId,
      name: botId,
    });

    yield put(
      actions.publishLexSuccess(
        data.status === 200 ? 'published' : 'not-published'
      )
    );

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_publish_lex')
    );
  } catch (error) {
    const messageError = JSON.parse(error?.request?.response || '');

    if (messageError?.message?.includes('Status')) {
      toast.error(
        i18n.t('error.error'),
        `${i18n.t('error.error_publish_lex')} ${messageError?.message}`
      );
    } else {
      toast.error(i18n.t('error.error'), i18n.t('error.error_publish_lex'));
    }
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* buildLex(action) {
  const { payload } = action;

  try {
    yield put(commonLoadingStart());
    yield call(apiIa.post, `/lex/build`, {
      token: payload,
    });

    yield put(actions.publishLexSuccess('not-published'));

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_build_lex')
    );
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_build_lex'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([
  takeLatest(types.IA_LIST_PROVIDER, listIAProvider),
  takeLatest(types.IA_CREATE_PROVIDER, createIAProvider),
  takeLatest(types.IA_TEST_VALIDATE_TOKEN, validateToken),
  takeLatest(types.IA_TEST_VALIDATE_LEX_CREDENTIALS, validateLexCredentials),

  takeLatest(types.IA_UPDATE_APPLICATION, updateApplication),

  takeLatest(types.IA_CREATE_INTENTS, createIntents),
  takeLatest(types.IA_DELETE_INTENTS, deleteIntents),
  takeLatest(types.IA_LIST_INTENTS, listIntents),

  takeLatest(types.IA_LIST_SAMPLES, listSamples),
  takeLatest(types.IA_LIST_SAMPLES_INTENT, listSamplesIntent),
  takeLatest(types.IA_DELETE_SAMPLES, deleteSamples),
  takeLatest(types.IA_LIST_ALL_SAMPLES, listAllSamples),

  takeLatest(types.IA_PLATFORM_INFO, getIaInfo),
  takeLatest(types.IA_LIST_CONDITIONS, listConditions),
  takeLatest(types.IA_CURATION_BOT_LIST_MESSAGES, listCurationMessages),

  takeLatest(types.IA_DELETE_CONDITIONS, deleteCondition),
  takeLatest(types.IA_UPDATE_CONDITIONS, updateCondition),
  takeLatest(types.IA_CREATE_CONDITIONS, createCondition),

  takeLatest(types.IA_LIST_ENTITIES, listEntities),
  takeLatest(types.IA_LIST_ENTITY, listEntity),
  takeLatest(types.IA_CREATE_ENTITIES, createEntities),
  takeLatest(types.IA_DELETE_ENTITIES, deleteEntities),
  takeLatest(types.IA_DELETE_ENTITIES_OBJ, deleteEntitiesObj),
  takeLatest(types.IA_DELETE_ENTITIES_SYN, deleteEntitiesSyn),

  takeLatest(types.IA_CURATION_BOT_SAVE_APRIMORAMENTOS, saveAprimoramentos),
  takeLatest(types.IA_CURATION_BOT_ACCEPT_MESSAGE, acceptCurationMessage),
  takeLatest(types.IA_CURATION_BOT_IGNORE_MESSAGE, ignoreCurationMessage),

  takeLatest(types.IA_LIST_WIT, listWit),
  takeLatest(types.IA_CREATE_WIT, createWit),
  takeLatest(types.IA_UPDATE_APPLICATION, updateApplication),

  takeLatest(types.IA_BUILD_LEX, buildLex),
  takeLatest(types.IA_PUBLISH_LEX, publishLex),
]);
