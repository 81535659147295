import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    border: 0;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    box-sizing: border-box;
  }

  svg {
    fill: currentColor;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  ol, ul {
    list-style: none;
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration:none;
    color:var(--primary-600-light);

    &:hover{
      text-decoration:underline;
    }
  }

  p {
    line-height: 143%;
    letter-spacing: 0.018em;
  }

  button {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
  }

  html {
    font-size: 16px;
    -moz-osx-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
  }


  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  *::placeholder {
    color: var(--neutral-2);
  }

  *::-webkit-input-placeholder {
    color: var(--neutral-2);
  }

  ::-webkit-scrollbar {
    width:6px!important;
    height:6px!important;
    background-color: var(--bg);
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--neutral-1);
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--neutral-2);
  }
  ::-webkit-scrollbar-track{
    background-color: transform
  }

  body {
    color: var(--neutral-4);
    font-family: 'Archivo', -apple-system, BlinkMacSystemFont, sans-serif;
    min-height: calc(100vh);
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    text-rendering: optimizeLegibility;
    will-change: background-color;
  }

  :root {
    /**** Colors ****/
    --bg: #F3F5F9;
    --absolute-black: #242424;
    --absolute-white: #ffffff;
    --neutral-0: #f3f5f9;
    --neutral-1: #dadce3;
    --neutral-2: #979aa5;
    --neutral-3: #5a5d68;
    --neutral-4: #35373f;
    --neutral-jumbo: #777F88;
    --neutral-n1: #020304;
    --neutral-n90: #E0E4EB;
    --primary-100-light: #d6e4ff;
    --primary-200-light: #adc8ff;
    --primary-300-light: #84a9ff;
    --primary-400-light: #6690ff;
    --primary-500-light: #3366ff;
    --primary-600-light: #254edb;
    --primary-700-light: #1939b7;
    --primary-800-light: #102693;
    --primary-900-light: #15104e;
    --semantic-cyan-100-light: #c7f9ed;
    --semantic-cyan-200-light: #91f3e3;
    --semantic-cyan-300-light: #57dbd1;
    --semantic-cyan-400-light: #2db5b7;
    --semantic-cyan-500-light: #007b87;
    --semantic-cyan-600-light: #006074;
    --semantic-cyan-700-light: #004861;
    --semantic-cyan-800-light: #00344e;
    --semantic-cyan-900-light: #002540;
    --semantic-green-100-light: #e3f8cc;
    --semantic-green-200-light: #c2f19d;
    --semantic-green-300-light: #90d665;
    --semantic-green-400-light: #5ead3b;
    --semantic-green-500-light: #27770f;
    --semantic-green-600-light: #19660a;
    --semantic-green-700-light: #0e5507;
    --semantic-green-800-light: #054504;
    --semantic-green-900-light: #023906;
    --semantic-orange-100-light: #fbeeca;
    --semantic-orange-200-light: #f7da97;
    --semantic-orange-300-light: #e8b960;
    --semantic-orange-400-light: #d29639;
    --semantic-orange-500-light: #b56805;
    --semantic-orange-600-light: #9b5203;
    --semantic-orange-700-light: #823f02;
    --semantic-orange-800-light: #682e01;
    --semantic-orange-900-light: #562200;
    --semantic-red-100-light: #fae0d2;
    --semantic-red-200-light: #f5bba7;
    --semantic-red-300-light: #e18976;
    --semantic-red-400-light: #c45a50;
    --semantic-red-500-light: #9e2121;
    --semantic-red-600-light: #871821;
    --semantic-red-700-light: #711021;
    --semantic-red-800-light: #5b0a1f;
    --semantic-red-900-light: #4b061e;
    --status-blue: #3366FF;
    --status-green: #38b878;
    --status-orange: #f9a75b;
    --status-purple: #a638b8;
    --status-red: #f9675b;
    --status-yellow: #f9d65b;
    --boteria-block-neutral: var(--neutral-2);
    --boteria-block-green: var(--status-green);
    --boteria-block-orange: var(--status-orange);
    --boteria-block-blue: var(--status-blue);
    --boteria-block-purple: var(--status-purple);
    --boteria-block-black: var(--neutral-4);
    --boteria-block-zendesk: #00333B;
    --boteria-block-jira: #2684FF;
    --boteria-block-vtex: #F71963;
    --boteria-block-rdstation: #19C1CE;
    --boteria-block-glpi: #112779;
    --boteria-block-hsm: #3873B8;
    --boteria-block-chat-gpt: #12a37f;
    --boteria-block-topdesk: #e65038;
    --boteria-block-ia: #2db5b7;
    --boteria-block-leia: #44403c;
    --green-whatsapp: #055e55;
    --green-whatsapp-message: #D8FFC2;

    /**** Fonts ****/
    /** body-default **/
    --body-default-font-family: 'Archivo', 'Archivo-Regular';
    --body-default-font-size: 16px;
    --body-default-letter-spacing: 0.4px;
    --body-default-line-height: 150%;
    --body-default-font-weight: 400;
    --body-default-color: var(--neutral-3);
    /** body-default-strong **/
    --body-default-strong-font-family: 'Archivo', 'Archivo-SemiBold';
    --body-default-strong-font-size: 16px;
    --body-default-strong-letter-spacing: 0.4px;
    --body-default-strong-line-height: 150%;
    --body-default-strong-font-weight: 600;
    --body-default-strong-color: var(--neutral-3);
    /** body-large **/
    --body-large-font-family: 'Archivo', 'Archivo-Regular';
    --body-large-font-size: 20px;
    --body-large-letter-spacing: 0.2px;
    --body-large-line-height: 158%;
    --body-large-font-weight: 400;
    --body-large-color: var(--neutral-3);
    /** body-large-strong **/
    --body-large-strong-font-family: 'Archivo', 'Archivo-SemiBold';
    --body-large-strong-font-size: 20px;
    --body-large-strong-letter-spacing: 0.2px;
    --body-large-strong-line-height: 158%;
    --body-large-strong-font-weight: 600;
    --body-large-strong-color: var(--neutral-3);
    /** body-small **/
    --body-small-font-family: 'Archivo', 'Archivo-Regular';
    --body-small-font-size: 14px;
    --body-small-letter-spacing: 0.3px;
    --body-small-line-height: 143%;
    --body-small-font-weight: 400;
    --body-small-color: var(--neutral-3);
    /** body-small-strong **/
    --body-small-strong-font-family: 'Archivo', 'Archivo-SemiBold';
    --body-small-strong-font-size: 14px;
    --body-small-strong-letter-spacing: 0.3px;
    --body-small-strong-line-height: 143%;
    --body-small-strong-font-weight: 600;
    --body-small-strong-color: var(--neutral-3);
    /** button-caption **/
    --button-caption-font-family: 'Archivo', 'Archivo-SemiBold';
    --button-caption-font-size: 14px;
    --button-caption-letter-spacing: 0.8px;
    --button-caption-line-height: 114%;
    --button-caption-font-weight: 600;
    --button-caption-text-transform: uppercase;
    --button-caption-color: black;
    /** button-caption-tight **/
    --button-caption-tight-font-family: 'Archivo', 'Archivo-Medium';
    --button-caption-tight-font-size: 14px;
    --button-caption-tight-letter-spacing: -0.3px;
    --button-caption-tight-line-height: 114%;
    --button-caption-tight-font-weight: 500;
    --button-caption-tight-color: black;
    /** caption-default **/
    --caption-default-font-family: 'Archivo', 'Archivo-Regular';
    --caption-default-font-size: 12px;
    --caption-default-letter-spacing: 0.4px;
    --caption-default-line-height: 126%;
    --caption-default-font-weight: 400;
    --caption-default-color: var(--neutral-3);
    /** caption-small **/
    --caption-small-font-family: 'Archivo', 'Archivo-Medium';
    --caption-small-font-size: 10px;
    --caption-small-letter-spacing: 0px;
    --caption-small-line-height: 120%;
    --caption-small-font-weight: 500;
    --caption-small-color: var(--neutral-4);
     /** caption-small-tight **/
     --caption-small-tight-font-family: 'Archivo', 'Archivo-Medium';
    --caption-small-tight-font-size: 14px;
    --caption-small-tight-letter-spacing: 0px;
    --caption-small-tight-line-height: 114%;
    --caption-small-tight-font-weight: 600;
    --caption-small-tight-color: var(--neutral-3);
    /** h1-heavy **/
    --h1-heavy-font-family: 'Archivo', 'Archivo-Bold';
    --h1-heavy-font-size: 96px;
    --h1-heavy-letter-spacing: -2.5px;
    --h1-heavy-line-height: 120%;
    --h1-heavy-font-weight: 700;
    --h1-heavy-color: black;
    /** h1-normal **/
    --h1-normal-font-family: 'Archivo', 'Archivo-Regular';
    --h1-normal-font-size: 96px;
    --h1-normal-letter-spacing: -2.5px;
    --h1-normal-line-height: 120%;
    --h1-normal-font-weight: 400;
    --h1-normal-color: var(--neutral-4);
    /** h2-heavy **/
    --h2-heavy-font-family: 'Archivo', 'Archivo-Bold';
    --h2-heavy-font-size: 60px;
    --h2-heavy-letter-spacing: -1.5px;
    --h2-heavy-line-height: 120%;
    --h2-heavy-font-weight: 700;
    --h2-heavy-color: var(--neutral-4);
    /** h2-normal **/
    --h2-normal-font-family: 'Archivo', 'Archivo-Regular';
    --h2-normal-font-size: 60px;
    --h2-normal-letter-spacing: -1.5px;
    --h2-normal-line-height: 120%;
    --h2-normal-font-weight: 400;
    --h2-normal-color: var(--neutral-4);
    /** h3-heavy **/
    --h3-heavy-font-family: 'Archivo', 'Archivo-Bold';
    --h3-heavy-font-size: 48px;
    --h3-heavy-letter-spacing: -0.5px;
    --h3-heavy-line-height: 124%;
    --h3-heavy-font-weight: 700;
    --h3-heavy-color: var(--neutral-4);
    /** h3-normal **/
    --h3-normal-font-family: 'Archivo', 'Archivo-Regular';
    --h3-normal-font-size: 48px;
    --h3-normal-letter-spacing: -0.5px;
    --h3-normal-line-height: 124%;
    --h3-normal-font-weight: 400;
    --h3-normal-color: var(--neutral-4);
    /** h4-heavy **/
    --h4-heavy-font-family: 'Archivo', 'Archivo-Bold';
    --h4-heavy-font-size: 34px;
    --h4-heavy-letter-spacing: 0px;
    --h4-heavy-line-height: 128%;
    --h4-heavy-font-weight: 700;
    --h4-heavy-color: var(--neutral-4);
    /** h4-normal **/
    --h4-normal-font-family: 'Archivo', 'Archivo-Regular';
    --h4-normal-font-size: 34px;
    --h4-normal-letter-spacing: 0px;
    --h4-normal-line-height: 128%;
    --h4-normal-font-weight: 400;
    --h4-normal-color: var(--neutral-4);
    /** h5-heavy **/
    --h5-heavy-font-family: 'Archivo', 'Archivo-Bold';
    --h5-heavy-font-size: 24px;
    --h5-heavy-letter-spacing: 0px;
    --h5-heavy-line-height: 150%;
    --h5-heavy-font-weight: 700;
    --h5-heavy-color: var(--neutral-4);
    /** h5-normal **/
    --h5-normal-font-family: 'Archivo', 'Archivo-Regular';
    --h5-normal-font-size: 24px;
    --h5-normal-letter-spacing: 0px;
    --h5-normal-line-height: 150%;
    --h5-normal-font-weight: 400;
    --h5-normal-color: var(--neutral-4);
    /** h6-heavy **/
    --h6-heavy-font-family: 'Archivo', 'Archivo-Bold';
    --h6-heavy-font-size: 20px;
    --h6-heavy-letter-spacing: 0.2px;
    --h6-heavy-line-height: 150%;
    --h6-heavy-font-weight: 700;
    --h6-heavy-color: var(--neutral-4);
    /** h6-normal **/
    --h6-normal-font-family: 'Archivo', 'Archivo-Regular';
    --h6-normal-font-size: 20px;
    --h6-normal-letter-spacing: 0.2px;
    --h6-normal-line-height: 150%;
    --h6-normal-font-weight: 400;
    --h6-normal-color: var(--neutral-4);
    /** overline-large **/
    --overline-large-font-family: 'Archivo', 'Archivo-SemiBold';
    --overline-large-font-size: 12px;
    --overline-large-letter-spacing: 2px;
    --overline-large-line-height: 126%;
    --overline-large-font-weight: 600;
    --overline-large-text-transform: uppercase;
    --overline-large-color: var(--neutral-2);
    /** overline-small **/
    --overline-small-font-family: 'Archivo', 'Archivo-SemiBold';
    --overline-small-font-size: 10px;
    --overline-small-letter-spacing: 1.5px;
    --overline-small-line-height: 120%;
    --overline-small-font-weight: 600;
    --overline-small-text-transform: uppercase;
    --overline-small-color: var(--neutral-2);

    /**** Spacements ****/
    --spacement-small: 8px;
    --spacement-medium: 16px;
    --spacement-large: 24px;
    --spacement-xlarge: 32px;

    /**** Radius ****/
    --radius-small:4px;
    --radius-medium:24px;
    --radius-large:47px;

    --shadow-1: 0px 2px 4px rgba(24, 39, 75, 0.12), 0px 4px 4px rgba(24, 39, 75, 0.08);
  }

  .p-16 { padding: 16px !important; }
  .px-16 { padding: 0 16px !important; }
  .mr-8 { margin-right: 8px !important; }
  .mr-16 { margin-right: 16px !important; }
  .mx-auto { margin: 0 auto; }
  .mt-8 { margin-top: 8px !important; }
  .mt-16 { margin-top: 16px !important; }
  .mt-24 { margin-top: 24px !important; }
  .mb-4 { margin-bottom: 4px !important; }
  .mb-8 { margin-bottom: 8px !important; }
  .mb-12 { margin-bottom: 12px !important; }
  .mb-16 { margin-bottom: 16px !important; }
  .mb-24 { margin-bottom: 24px !important; }
  .mb-32 { margin-bottom: 32px !important; }
  .my-14 { margin: 14px 0 !important; }
  .my-12 { margin: 12px 0 !important; }
  .my-16 { margin: 16px 0 !important; }
  .my-8 { margin: 8px 0 !important; }
  .my-auto { margin: auto 0; }
  .ml-auto { margin-left: auto; }
  .ml-4 { margin-left: 4px; }

  .block { display: block; }
`;

export default GlobalStyles;
