import types from './types';

export const fetchIvrReportTemplates = ({ botId }) => ({
  type: types.FETCH_IVR_REPORT_TEMPLATES,
  payload: { botId },
});
export const fetchIvrReportTemplatesSuccess = (data) => ({
  type: types.FETCH_IVR_REPORT_TEMPLATES_SUCCESS,
  payload: data,
});
export const fetchIvrReportTemplatesFailure = () => ({
  type: types.FETCH_IVR_REPORT_TEMPLATES_FAILURE,
});

export const searchIvrReportTemplates = ({ botId, page, limit, search }) => ({
  type: types.SEARCH_IVR_REPORT_TEMPLATES,
  payload: { botId, page, limit, search },
});
export const searchIvrReportTemplatesSuccess = (data) => ({
  type: types.SEARCH_IVR_REPORT_TEMPLATES_SUCCESS,
  payload: data,
});
export const searchIvrReportTemplatesFailure = () => ({
  type: types.SEARCH_IVR_REPORT_TEMPLATES_FAILURE,
});

export const createIvrReportTemplate = (data, callback) => ({
  type: types.CREATE_IVR_REPORT_TEMPLATE,
  payload: { data, callback },
});
export const createIvrReportTemplateSuccess = (data) => ({
  type: types.CREATE_IVR_REPORT_TEMPLATE_SUCCESS,
  payload: data,
});
export const createIvrReportTemplateFailure = () => ({
  type: types.CREATE_IVR_REPORT_TEMPLATE_FAILURE,
});

export const updateIvrReportTemplate = (data, callback) => ({
  type: types.UPDATE_IVR_REPORT_TEMPLATE,
  payload: { data, callback },
});
export const updateIvrReportTemplateSuccess = (data) => ({
  type: types.UPDATE_IVR_REPORT_TEMPLATE_SUCCESS,
  payload: data,
});
export const updateIvrReportTemplateFailure = () => ({
  type: types.UPDATE_IVR_REPORT_TEMPLATE_FAILURE,
});

export const cloneIvrReportTemplate = ({ _id, targetBotId }, callback) => ({
  type: types.CLONE_IVR_REPORT_TEMPLATE,
  payload: { _id, targetBotId, callback },
});
export const cloneIvrReportTemplateSuccess = (data) => ({
  type: types.CLONE_IVR_REPORT_TEMPLATE_SUCCESS,
  payload: data,
});
export const cloneIvrReportTemplateFailure = () => ({
  type: types.CLONE_IVR_REPORT_TEMPLATE_FAILURE,
});

export const deleteIvrReportTemplate = (_id, callback) => ({
  type: types.DELETE_IVR_REPORT_TEMPLATE,
  payload: { _id, callback },
});
export const deleteIvrReportTemplateSuccess = (data) => ({
  type: types.DELETE_IVR_REPORT_TEMPLATE_SUCCESS,
  payload: data,
});
export const deleteIvrReportTemplateFailure = () => ({
  type: types.DELETE_IVR_REPORT_TEMPLATE_FAILURE,
});

export const searchIvrReportCalls = (data) => ({
  type: types.SEARCH_IVR_REPORT_CALLS,
  payload: data,
});
export const searchIvrReportCallsSuccess = (data) => ({
  type: types.SEARCH_IVR_REPORT_CALLS_SUCCESS,
  payload: data,
});
export const searchIvrReportCallsFailure = () => ({
  type: types.SEARCH_IVR_REPORT_CALLS_FAILURE,
});
export const resetSearchIvrReportCalls = () => ({
  type: types.RESET_SEARCH_IVR_REPORT_CALLS,
});

export const generateIvrReportCalls = (data, callback) => ({
  type: types.GENERATE_IVR_REPORT_CALLS,
  payload: { data, callback },
});
export const generateIvrReportCallsSuccess = (data) => ({
  type: types.GENERATE_IVR_REPORT_CALLS_SUCCESS,
  payload: data,
});
export const generateIvrReportCallsFailure = () => ({
  type: types.GENERATE_IVR_REPORT_CALLS_FAILURE,
});

export const searchIvrReports = (data) => ({
  type: types.SEARCH_IVR_REPORTS,
  payload: data,
});
export const searchIvrReportsSuccess = (data) => ({
  type: types.SEARCH_IVR_REPORTS_SUCCESS,
  payload: data,
});
export const searchIvrReportsFailure = () => ({
  type: types.SEARCH_IVR_REPORTS_FAILURE,
});

export const deleteIvrReport = (id, callback) => ({
  type: types.DELETE_IVR_REPORT,
  payload: { id, callback },
});
export const deleteIvrReportSuccess = () => ({
  type: types.DELETE_IVR_REPORT_SUCCESS,
});
export const deleteIvrReportFailure = () => ({
  type: types.DELETE_IVR_REPORT_FAILURE,
});

export const downloadIvrReport = (id, callback) => ({
  type: types.DOWNLOAD_IVR_REPORT,
  payload: { id, callback },
});
export const downloadIvrReportSuccess = () => ({
  type: types.DOWNLOAD_IVR_REPORT_SUCCESS,
});
export const downloadIvrReportFailure = () => ({
  type: types.DOWNLOAD_IVR_REPORT_FAILURE,
});

export const searchIvrReportDashboard = (data) => ({
  type: types.SEARCH_IVR_REPORT_DASHBOARD,
  payload: data,
});
export const searchIvrReportDashboardSuccess = (data) => ({
  type: types.SEARCH_IVR_REPORT_DASHBOARD_SUCCESS,
  payload: data,
});
export const searchIvrReportDashboardFailure = () => ({
  type: types.SEARCH_IVR_REPORT_DASHBOARD_FAILURE,
});

export const searchIvrReportErrors = (data) => ({
  type: types.SEARCH_IVR_REPORT_ERRORS,
  payload: data,
});
export const searchIvrReportErrorsSuccess = (data) => ({
  type: types.SEARCH_IVR_REPORT_ERRORS_SUCCESS,
  payload: data,
});
export const searchIvrReportErrorsFailure = () => ({
  type: types.SEARCH_IVR_REPORT_ERRORS_FAILURE,
});

export const fetchIvrReportDashboardTemplates = (data) => ({
  type: types.FETCH_IVR_REPORT_DASHBOARD_TEMPLATES,
  payload: data,
});
export const fetchIvrReportDashboardTemplatesSuccess = (data) => ({
  type: types.FETCH_IVR_REPORT_DASHBOARD_TEMPLATES_SUCCESS,
  payload: data,
});
export const fetchIvrReportDashboardTemplatesFailure = () => ({
  type: types.FETCH_IVR_REPORT_DASHBOARD_TEMPLATES_FAILURE,
});

export const createIvrReportDashboardTemplate = (data, callback) => ({
  type: types.CREATE_IVR_REPORT_DASHBOARD_TEMPLATE,
  payload: { data, callback },
});
export const createIvrReportDashboardTemplateSuccess = (data) => ({
  type: types.CREATE_IVR_REPORT_DASHBOARD_TEMPLATE_SUCCESS,
  payload: { data },
});
export const createIvrReportDashboardTemplateFailure = () => ({
  type: types.CREATE_IVR_REPORT_DASHBOARD_TEMPLATE_FAILURE,
});

export const updateIvrReportDashboardTemplate = (data, callback) => ({
  type: types.UPDATE_IVR_REPORT_DASHBOARD_TEMPLATE,
  payload: { data, callback },
});
export const updateIvrReportDashboardTemplateSuccess = (data) => ({
  type: types.UPDATE_IVR_REPORT_DASHBOARD_TEMPLATE_SUCCESS,
  payload: { data },
});
export const updateIvrReportDashboardTemplateFailure = () => ({
  type: types.UPDATE_IVR_REPORT_DASHBOARD_TEMPLATE_FAILURE,
});

export const deleteIvrReportDashboardTemplate = (id, callback) => ({
  type: types.DELETE_IVR_REPORT_DASHBOARD_TEMPLATE,
  payload: { id, callback },
});
export const deleteIvrReportDashboardTemplateSuccess = (id) => ({
  type: types.DELETE_IVR_REPORT_DASHBOARD_TEMPLATE_SUCCESS,
  payload: { id },
});
export const deleteIvrReportDashboardTemplateFailure = () => ({
  type: types.DELETE_IVR_REPORT_DASHBOARD_TEMPLATE_FAILURE,
});

export const fetchIvrReportDashboardFromTemplate = ({
  template_id,
  dt_start,
  dt_finish,
  code_flow_ivr,
  did_number,
}) => ({
  type: types.FETCH_IVR_REPORT_DASHBOARD_FROM_TEMPLATE,
  payload: { template_id, dt_start, dt_finish, code_flow_ivr, did_number },
});
export const fetchIvrReportDashboardFromTemplateSuccess = (data) => ({
  type: types.FETCH_IVR_REPORT_DASHBOARD_FROM_TEMPLATE_SUCCESS,
  payload: { data },
});
export const fetchIvrReportDashboardFromTemplateFailure = () => ({
  type: types.FETCH_IVR_REPORT_DASHBOARD_FROM_TEMPLATE_FAILURE,
});
export const resetFetchIvrReportDashboardFromTemplate = () => ({
  type: types.RESET_FETCH_IVR_REPORT_DASHBOARD_FROM_TEMPLATE,
});
