import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal, { ModalFooter, ModalContent } from '~/components/Modal';
import Button from '~/components/Button';
import Input from '~/components/Input';
import TextArea from '~/components/TextArea';
import LengthCounter from '~/components/LengthCounter';

import * as S from './styles';

function ModalCreateBot(props) {
  const {
    isOpen,
    setIsOpen,
    extraContent,
    templateId,
    fromBotId,
    createBot,
    isLoading,
    selectedCompany,
    selectedOrganization,
    setOpenedModalBot,
  } = props;

  const { t } = useTranslation();
  const initialState = { title: '', description: '' };
  const [fields, setFields] = useState(initialState);

  const onChange = (event) => {
    const { name, value } = event.target;
    setFields({ ...fields, [name]: value });
  };

  const onClose = () => {
    setOpenedModalBot('');
    setIsOpen(false);
    setFields(initialState);
  };

  const onSubmit = () => {
    const payload = {
      ...fields,
      title: fields.title || t('labels.new_bot'),
      type: 'chat',
      deleted: false,
      isTemplate: false,
      companyId: selectedCompany?._id,
      organizationId: selectedOrganization,
    };

    if (templateId !== '' && fromBotId !== '') {
      payload.templateId = templateId;
      payload.botId = fromBotId;
    }

    createBot(payload);
    onClose();
  };

  return (
    <Modal
      maxWidth="413px"
      isOpen={isOpen}
      onClose={onClose}
      title={t('labels.new_bot')}
      isFixed
    >
      <ModalContent>
        <S.Content>
          <Input
            name="title"
            label={t('labels.bot_name')}
            placeholder="ex: Petshop"
            onChange={onChange}
            value={fields?.title}
            maxLength={26}
          />
          <div>
            <TextArea
              name="description"
              label={t('labels.bot_description')}
              onChange={onChange}
              value={fields?.description}
              maxLength={150}
            />
            <LengthCounter value={fields?.description || ''} maxLength={150} />
            {extraContent}
          </div>
        </S.Content>
      </ModalContent>

      <ModalFooter>
        <Button data-testid="cancel" onClick={onClose} variant="outline">
          {t('buttons.cancel')}
        </Button>
        <Button data-testid="submit" onClick={onSubmit} loading={isLoading}>
          {t('buttons.create')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ModalCreateBot.propTypes = {
  extraContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  templateId: PropTypes.string,
  fromBotId: PropTypes.string,
  createBot: PropTypes.func,
  isLoading: PropTypes.bool,
  selectedCompany: PropTypes.shape({
    _id: PropTypes.string,
  }),
  selectedOrganization: PropTypes.string,
  setOpenedModalBot: PropTypes.func,
};

ModalCreateBot.defaultProps = {
  extraContent: '',
  isOpen: false,
  setIsOpen: () => {},
  templateId: '',
  fromBotId: '',
  createBot: () => {},
  isLoading: false,
  selectedCompany: null,
  selectedOrganization: '',
  setOpenedModalBot: () => {},
};

export default ModalCreateBot;
