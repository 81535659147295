import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import { toast } from '~/components/CustomToast';
import i18n from '~/translations/i18n';

import {
  commonLoadingStart,
  commonLoadingFinish,
  commonChangingFinish,
} from '../common/actions';
import { updateBotLastUpdate } from '../bots/actions';
import * as actions from './actions';
import types from './types';

export function* fetchNps(action) {
  const { botId } = action.payload;

  try {
    yield put(commonLoadingStart());

    const { data } = yield call(api.get, `/v3/bots/${botId}/nps`);
    yield put(actions.fetchNpsSuccess(data));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_load_triggers'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* createNps(action) {
  const { botId, nps, isCopy } = action.payload;

  try {
    yield put(commonLoadingStart());

    const { data } = yield call(api.post, `/v3/bots/${botId}/nps`, nps);

    if (data?._id) {
      yield put(actions.createNpsSuccess(data));

      if (isCopy) {
        toast.success(
          i18n.t('success.success'),
          i18n.t('success.success_copy_nps')
        );
      } else {
        toast.success(
          i18n.t('success.success'),
          i18n.t('success.success_create_nps')
        );
      }
    }
    yield put(commonChangingFinish());
  } catch (error) {
    if (isCopy) {
      toast.success(i18n.t('error.error'), i18n.t('error.error_copy_nps'));
    } else {
      toast.success(i18n.t('error.error'), i18n.t('error.error_create_nps'));
    }
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateNps(action) {
  const { botId, nps } = action.payload;
  const { _id: npsId } = nps;

  try {
    yield put(commonLoadingStart());

    const { data } = yield call(api.put, `/v3/bots/${botId}/nps/${npsId}`, nps);
    if (data?._id) {
      yield put(actions.updateNpsSuccess(data));
      if (data.isPublished) {
        yield put(updateBotLastUpdate({ botId }));
      }

      if (!data?.isPublished) {
        toast.success(
          i18n.t('success.success'),
          i18n.t('success.success_update_nps')
        );
      } else {
        toast.success(
          i18n.t('success.success'),
          i18n.t('success.success_publish_nps')
        );
      }
    }
    yield put(commonChangingFinish());
  } catch (error) {
    if (nps?.isPublished) {
      toast.error(i18n.t('error.error'), i18n.t('error.error_publish_nps'));
    } else {
      toast.error(i18n.t('error.error'), i18n.t('error.error_delete_nps'));
    }
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* deleteNps(action) {
  const { botId, npsId, isPublished } = action.payload;

  try {
    yield put(commonLoadingStart());
    yield call(api.delete, `/v3/bots/${botId}/nps/${npsId}`);
    if (isPublished) {
      yield put(updateBotLastUpdate({ botId }));
    }
    yield put(actions.deleteNpsSuccess({ npsId }));
    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_delete_nps')
    );
    yield put(commonChangingFinish());
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_delete_nps'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([
  takeLatest(types.FETCH_NPS_REQUEST, fetchNps),
  takeLatest(types.CREATE_NPS_REQUEST, createNps),
  takeLatest(types.UPDATE_NPS_REQUEST, updateNps),
  takeLatest(types.DELETE_NPS_REQUEST, deleteNps),
]);
